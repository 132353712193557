.footer {
    width: 100vw;
    height: 90vh;
    margin-top: 4rem;
    margin-bottom: 2rem;
    background: #98652f;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footerImg {
    height: min-content;
    width: auto;
}

.bio {
    margin-top: 4rem;
    margin-bottom: 2rem;
}

.footer h2 {
    font-family: American Typewriter, serif;
    font-size: 1.50rem;
    color: white;
    text-align: center;
}

.footer p {
    font-family: American Typewriter, serif;
    font-size: 1.25rem;
    color: white;
    text-align: center;
    margin-top: 1.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
}

.bottomFooter {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-style: italic;
}

.time {
    margin-top: 4rem;
    font-weight: bold;
}

.address {
    font-weight: bold;
}


@media (min-width: 200px) and (max-width: 810px) {
    .path {
      width: 300;
      height: 85.5px;
    }
    .footerImg {
        height: min-content;
        width: min-content;
        margin-top: 1rem;
    }

    .footer h4 {
        /* font-size: 1rem; */
        padding: 1rem; 
    }

    .footer p {
        font-size: 1rem;
    }

    .bottomFooter {
        margin-bottom: 4rem;
    }

  }


