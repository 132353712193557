.contactGrid {
    display: grid;
    width: 50%;
    height: min-content;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    justify-self: center;
    margin-bottom: 50px;
}

#pie, #text {
    visibility: hidden;
}

@media (min-width: 200px) and (max-width: 790px) {
    .contactGrid {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}