.map {
    width: 100%;
    height: 30rem;
    /* border-radius: 8px; */
    border: none;
    overflow: none;
}


.mapContainer {
    width: 100%;
    height: min-content;
    overflow: hidden;
    margin-bottom: 4rem;
}

@media (min-width: 200px) and (max-width: 790px) {
    .mapContainer {
        margin-bottom: 1rem;
    }
}