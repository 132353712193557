.header {
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: #98652f;
    margin-top: 4rem;
    margin-bottom: 4rem;
    /* background-image: url('../../assets/donaldsheader.png');
    background-position: center;
    background-size:cover; */
  }
  
  img {
    height: 100vh;
    width: auto;
  }

 #logo {
  stroke-width: 2px;
  stroke: red;
 }

@media (min-width: 200px) and (max-width: 790px) {
  .header {
    height: 50vh;
  }
  img {
    height: 50vh;
    width: auto;
  }
}