.App {
  width: 100vw;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr); */
  background-color: #98652f;
  overflow-x: hidden;
}


@media (min-width: 200px) and (max-width: 790px) {
  .App {
    overflow-x: hidden;
  }
}